/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import './theme/dependencies/bootstrap/css/bootstrap.min.css'
import './theme/dependencies/fontawesome/css/all.min.css'
import './theme/dependencies/animate/animate.min.css'
import './theme/dependencies/meanmenu/css/meanmenu.min.css'
import './theme/dependencies/flaticon/fonts/flaticon.css'
import './theme/dependencies/magnific-popup/css/magnific-popup.css'
import './theme/dependencies/owl.carousel/css/owl.carousel.min.css'
import './theme/dependencies/owl.carousel/css/owl.theme.default.min.css'
import './theme/switcher/css/dark/azure-radiance.css'
